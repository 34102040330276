import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    redirect: "/home",
    component: (resolve) => require(["@/views/layout/index.vue"], resolve),
    meta: { showfoote: true },
    children: [
      {
        path: "home",
        name: "home",
        component: (resolve) => require(["@/views/home/home.vue"], resolve),
        meta: { showfoote: true },
      },
      {
        path: "searchDetail",
        name: "searchDetail",
        component: (resolve) =>
          require(["@/views/searchDetail/searchDetail.vue"], resolve),
        meta: { showfoote: false },
      },
      {
        path: "detailPage",
        name: "detailPage",
        component: (resolve) =>
          require(["@/views/detailPage/detailPage.vue"], resolve),
        meta: { showfoote: false },
      },
      {
        path: "indexPage",
        name: "indexPage",
        component: (resolve) =>
          require(["@/views/indexPage/indexPage.vue"], resolve),
        meta: { showfoote: false },
      },
      {
        path: "personalCenter",
        name: "personalCenter",
        component: (resolve) =>
          require(["@/views/personalCenter/personalCenter.vue"], resolve),
        meta: { showfoote: false },
      },
      {
        path: "login",
        name: "login",
        component: (resolve) => require(["@/views/login/login.vue"], resolve),
        meta: { showfoote: false },
      },
      {
        path: "loginIdt",
        name: "loginIdt",
        component: (resolve) => require(["@/views/login/loginIdt.vue"], resolve),
        meta: { showfoote: false },
      },
      {
        path: "mdArticle",
        name: "mdArticle",
        component: (resolve) =>
          require(["@/views/indexPage/mdArticle.vue"], resolve),
        meta: { showfoote: false },
      },
    ],
  },
  {
    path: "/detailMain",
    name: "detailMain",
    component: (resolve) =>
      require(["@/views/detailPage/mainContent.vue"], resolve),
    meta: { showMain: true },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
