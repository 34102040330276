import request from '@/utils/request'

export function getMenuInfo(data){
  return request({
    url:'api-kb/menu/list',
    method:'post',
    data:data
  })
}
// export function getMenuInfo(data){
//   return request({
//     url:'/menu/list',
//     method:'post',
//     data:data
//   })
// }

export function searchInfo(data){
    return request({
      url:'api-kb/article/front/search',
      method:'post',
      data:data
    })
}
// export function searchInfo(data){
//     return request({
//       url:'/article/front/search',
//       method:'post',
//       data:data
//     })
// }

export function getArticleDetail(data){
    return request({
      url:'api-kb/article/front/detail',
      method:'post',
      headers:{
        'Content-Type': 'application/json; charset=UTF-8',
      },
      data:data
    })
}
// export function getArticleDetail(data){
//     return request({
//       url:'/article/front/detail',
//       method:'post',
//       data:data
//     })
// }

export function getVersionSwitch(data){
    return request({
      url:'api-kb/article/front/versionSwitch',
      method:'post',
      data:data
    })
}
//获取当前语言设备版本号
export function getProductVersionList(data){
    return request({
      url:'api-kb/article/front/productVersionList',
      method:'post',
      data:data
    })
}
// export function getVersionSwitch(data){
//     return request({
//       url:'/article/front/versionSwitch',
//       method:'post',
//       data:data
//     })
// }
//评论
export function sendComment(data){
    return request({
      url:'api-kb/comment/insert',
      method:'post',
      data:data
    })
}
//下载文件
export function downloadFile(data){
    return request({
      url:'api-file/kb/filedfs/download',
      headers:{
        'Content-Type': 'application/json; charset=UTF-8',
      },
      responseType: 'blob',
      method:'post',
      data:data
    })
}
//统计文件下载量
export function addDownloadsNum(data){
    return request({
      url:'api-kb/article/front/addDownloadsNum',
      method:'post',
      data:data
    })
}
//文章标题
export function getMarkDown(data){
    return request({
      url:'api-kb/article/front/markDown',
      method:'post',
      data:data
    })
}
//markdown章节
export function getCatalogueByAticle(data){
    return request({
      url:'api-kb/catalogue/getCatalogueByAticle',
      method:'post',
      headers:{
        'Content-Type': 'application/json; charset=UTF-8',
      },
      data:data
    })
}
//markdown内容
export function getById(data){
    return request({
      url:'api-kb/catalogue/getfrontById',
      method:'post',
      headers:{
        'Content-Type': 'application/json; charset=UTF-8',
      },
      data:data
    })
}

//无内容跳转下一章
export function jumpNextChapter(data){
    return request({
      url:'api-kb/catalogue/getNoContCatalogue',
      method:'post',
      data:data
    })
}
export function sendHtml(data){
    return request({
      url:'api-kb/menu/pdf',
      headers:{
        'Content-Type': 'application/json; charset=UTF-8',
      },
      responseType: 'blob',
      method:'post',
      data:data
    })
}
//获取md文章章节
export function getMdArticleChapter(data){
  return request({
    url:'api-kb/article/front/getFirstLevelByArticleId',
    method:'post',
    headers:{
      'Content-Type': 'application/json; charset=UTF-8',
    },
    data:data
  })
}
//获取md文章章节
export function getMarkdownArticle(data){
  return request({
    url:'api-kb/article/front/searchMarkdownArticle',
    method:'post',
    data:data
  })
}
// 统计来源是email和workplace
export function frontSave(data){
  return request({
    url:'api-kb/article/front/save',
    method:'post',
    data:data
  })
}
