import router from "./router";
import store from "./store";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { getMenuInfo } from "@/api/common.js";

let loadAsyncRouter = false;
router.beforeEach((to, from, next) => {
  NProgress.start();
  if (!loadAsyncRouter && !to.meta.showMain) {
    if (to.meta.showfoote) {
      localStorage.setItem("library_isHome", true);
      getMenuInfo().then((res) => {
        loadAsyncRouter = true;
        localStorage.setItem("library_menuData", JSON.stringify(res.data.rows));
        store.commit("SET_MENUDATA", res.data.rows);
        next();
      });
    } else {
      getMenuInfo().then((res) => {
        loadAsyncRouter = true;
        localStorage.setItem("library_menuData", JSON.stringify(res.data.rows));
        store.commit("SET_MENUDATA", res.data.rows);
        next();
      });
    }
  } else {
    next();
  }
});

router.afterEach(() => {
  NProgress.done(); // 结束Progress
});
