import request from '@/utils/request'
import axios from 'axios'
const prefix = process.env.VUE_APP_IDT_AXIOS;
export function loginGsm(data) {
  return request({
    url: '/api-auth/oauth/user/token',
    method: 'post',
    data: data,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      "client_id": "sangfor",
      "client_secret": "webApp",
    }
  })
}

export function loginPp(data) {
  return request({
    url: '/api-auth/oauth/user/pptoken',
    method: 'post',
    data: data,
    headers: {
      client_id: 'sangfor',
      client_secret: 'webApp',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
  })
}

export function outPp(data) {
  return request({
    url: '/api-auth/oauth/remove/pptoken',
    method: 'post',
    data: data,
    headers: {
      client_id: 'sangfor',
      client_secret: 'webApp',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
  })
}

export function outGsm(data) {
  return request({
    url: '/api-auth/oauth/remove/token',
    method: 'post',
    data: data,
    headers: {
      client_id: 'sangfor',
      client_secret: 'webApp',
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    },
  })
}

export function getPpuserInfo() {
  return request({
    url: '/api-user/sys/ppuser/info',
    method: 'get',
  })
}
export function getGsmInfo() {
  return request({
    url: '/api-user/sys/user/info',
    method: 'get',
  })
}
export function getUserAndTokenByCode(data){
  return axios({
    url:`${prefix}/api-auth/gsmOauth/getUserAndTokenByCode`,
    method:'post',
    data
  })
}

export function gsmOauthInfo(workNo){
  return axios({
    url:`${prefix}/api-auth/gsmOauth/info`,
    method:'get',
    params:{
      workNo
    },
    headers:{
      client_id: 'sangfor',
      client_secret: 'webApp'
    }
  })
}
export function removeIdtToken(token){
  return axios({
    url:`${prefix}/api-auth/gsmOauth/remove/token`,
    method:'post',
    params:{
      token
    }
  })
}

export function sendEmail(data) {
  return request({
    url: '/sf-partnerportal/pp/user/ppLoginMail',
    method: 'post',
    data: data
  })
}
export function menuTreeList(id) {
  return request({
    url: '/api-kb/article/front/menuTreeList',
    method: 'post',
    data: id
  })
}

const prefixList = {
  development: '',
  sit: '/api-kb',
  uat: '/api-kb',
  production: '/api-kb'
}
const { NODE_ENV } = process.env
export function checkLoginApi() {
  return request({
    url: `${prefixList[NODE_ENV]}/system/gpt/isGsmUser`,
    method: 'post'
  })
}