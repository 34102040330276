import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import locale from 'element-ui/lib/locale/lang/en'
import './utils/rem'
import './assets/css/common.css'
import ElementUI from 'element-ui'
import VEmojiPicker from 'v-emoji-picker';
Vue.config.productionTip = false;
Vue.use(VEmojiPicker);
import drag from '@/utils/drag.js';
import '@/icons'
import 'element-ui/lib/theme-chalk/index.css'
import '@/permission'

Vue.config.productionTip = false
Vue.prototype.$moment = moment;
Vue.use(ElementUI,{locale})
Vue.use(drag)
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
//图片点击放大
Viewer.setDefaults({
  title:false//隐藏图片title
  // Options: { 'inline': true, 'button': true, 'navbar': true, 'title': false, 'toolbar': true, 'tooltip': true, 'movable': true, 'zoomable': true, 'rotatable': true, 'scalable': true, 'transition': true, 'fullscreen': true, 'keyboard': true, 'url': 'data-source' }
})
// 时间格式化过滤器
Vue.filter('dateformat', function (dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
  return moment(dataStr).format(pattern)
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
