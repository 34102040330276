<template>
  <div id="app" v-if="tokenAlive">
    <router-view v-if="isRouterAlive" />
  </div>
</template>
<script>
import { getGsmInfo,getPpuserInfo,menuTreeList } from "./api/login";
export default {
  name: "App",
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true, 
      tokenAlive:true
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false; 
      this.$nextTick(function () {
        this.isRouterAlive = true; 
      });
    },
  },
  created () {
    this.tokenAlive = false
    //pp gsm  携带token跳转自动登录
    if(decodeURIComponent(window.location.search).indexOf('token')!==-1){
    var token,type,data,jform
    var url = decodeURIComponent(window.location.search)
    if(decodeURIComponent(window.location.href).indexOf('home')!==-1){
      if(url.indexOf('jform')!==-1){
        data=JSON.parse(url.split('?')[1].split('&')[0].split('=')[1])
        token = url.split('?')[1].split('&')[1].split('=')[1]
        jform=url.split('?')[1].split('&')[2].split('=')[1]
        type=1
        console.log(jform);
      }else{
        token = url.split('?')[1].split('=')[1]
        type = url.split('?')[2]//1:gsm  2:pp
      }
    }else  if(decodeURIComponent(window.location.href).indexOf('indexPage')!==-1){
      token = url.split('?')[2].split('=')[1]
      type = url.split('?')[3]//1:gsm  2:pp
      var menuid = url.split('?')[1].split('=')[1]
    }
      localStorage.setItem("library_token", token);
      localStorage.setItem("loginFailTime", 0);
      localStorage.setItem("readPrivacyPolicy", 0);
      if(type==1){
        getGsmInfo().then((res) => {
        localStorage.setItem("library_userInfo",JSON.stringify(res.data.user));
        this.$store.commit("SET_USERINFO", res.data.user);
        localStorage.setItem('library_userType','gsm')
        this.$router.push({path:`/home`})
        if(jform=='sp'){
          this.$router.push({
            path: "/detailPage",
            query: { articleData: JSON.stringify(data) },
          });
        }
        this.tokenAlive = true
      });
      }
      else if(type==2){
        getPpuserInfo().then(res=>{
        localStorage.setItem('library_userInfo',JSON.stringify(res.data.user))
        localStorage.setItem('library_userType','pp')
        this.$store.commit('SET_USERINFO',res.data.user)
        menuTreeList(menuid).then(res=>{
          console.log(res.data.rows[0]);
          localStorage.setItem('library_tree',JSON.stringify(res.data.rows[0]))
          console.log(window.location.href);
          this.$router.push({name:`indexPage`,params:{detailId:menuid},query:{module:menuid}})
          this.tokenAlive = true
        })
      })
      }    
    }else{
      this.tokenAlive = true
    }
    
  }
};
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
</style>
