import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import {menuTreeList} from "../api/login"
export default new Vuex.Store({
  state: {
    userInfo: {},
    menuData: [],
  },
  getters: {
    userInfo(state) {
      return state.userInfo;
    },
    menuData(state) {
      return state.menuData;
    },
  },
  mutations: {
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo;
    },
    SET_MENUDATA: (state, menuData) => {
      state.menuData = menuData;
    },
  },
  actions: {
    //获取左侧树
    menuTreeList({commit},data){
      return new Promise((resolve, reject) => {
        menuTreeList(data).then(res=>{
          localStorage.setItem('library_tree',JSON.stringify(res.data.rows[0]))
          resolve(res)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  modules: {},
});
