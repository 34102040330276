import axios from 'axios'
import { Message } from 'element-ui'
import router from '../router'
import qs from 'qs'
axios.defaults.headers['Content-Type'] = 'application/json'
axios.defaults.headers['Accept-Language'] = 'en'
// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL, // api 的 base_url
  timeout: 30000 // 请求超时时间
})
//请求拦截器
service.interceptors.request.use(
  config => {
    // var configData = config.data;
    const flag = config.headers['Content-Type'] && config.headers['Content-Type'].indexOf('application/json') !== -1
    if (!flag) {
      const mult = config.headers['Content-Type'] && config.headers['Content-Type'].indexOf('multipart/form-data') !== -1
      if (mult) {
        config.data = config.data
      } else {
        config.data = qs.stringify(config.data)
      }
    }
    //如果存在token，请求携带这个token
    if (window.localStorage.getItem('library_token')) {
      config.headers['Authorization'] = 'Bearer ' + window.localStorage.getItem('library_token')
    }
    return config
  },
  error => {
    console.log(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  res => {
    // 未设置状态码则默认成功状态
    return res
  },
  error => {
    console.log('err' + error)
    console.log(error.response)
    if (error.response.data.size > 0) {
      return error.response.data
    }
    if (error.response.code == 400 || error.response.code == 500) {
      Message({
        message: error.response.msg,
        type: 'error',
        duration: 4 * 1000,
        showClose: true
      })
    }

    if (error.response.status === 404) {
      localStorage.removeItem('library_token')
      localStorage.removeItem('library_userInfo')
      localStorage.removeItem('library_userType')
      delete service.defaults.headers['Authorization']
      if (router.currentRoute.path != '/') {
        let url = 'http://' + location.host + '/'
        location.href = url
        // router.replace({path:"/"});
      }
    } else if (error.response.status === 403) {
      if (error.response.data.code == 'FORBIDDEN') {
        Message({
          message: error.response.data.msg.split(',')[1],
          type: 'warning',
          duration: 4 * 1000,
          showClose: true
        })
      } else if (error.response.data.msg == '用户已作废') {
        Message({
          message: 'The user is invalid',
          type: 'warning',
          duration: 4 * 1000,
          showClose: true
        })
      } else if (error.response.data.msg == '') {
        Message({
          message: 'No permission',
          type: 'warning',
          duration: 4 * 1000,
          showClose: true
        })
      } else {
        Message({
          message: error.response.data.msg,
          type: 'warning',
          duration: 4 * 1000,
          showClose: true
        })
      }
    } else if (error.response.status === 401) {
      localStorage.removeItem('library_userType')
      localStorage.removeItem('library_token')
      localStorage.removeItem('library_userInfo')
      delete service.defaults.headers['Authorization']
      let url = 'http://' + location.host + '/'
      location.href = url
    }

    if (error.response.status === 500) {
      var msg = error.response.data.errorMsg
      if (msg == null || msg == undefined) {
        msg = error.response.data.msg
      }
      if (msg == null || msg == undefined) {
        msg = 'Data exception, please contact administrator!'
      }

      Message({
        message: msg,
        type: 'error',
        duration: 4 * 1000,
        showClose: true
      })
    }
    return Promise.reject(error)
  }
)

export default service
